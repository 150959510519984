<template>
	<div class="row">
      <div class="col-12" v-if="telaAjuda[$route.path.substring(1, $route.path.lastIndexOf('/'))]">
         <div class="alert alert-secondary alert-dismissible fade show mb-2" role="alert">
            <strong>{{ $route.name != null ? $route.name.replace(/_/g, " ").replace(/-/g, "/") : '' }}</strong><i class="far fa-arrow-alt-right font-10 mx-2"></i>
            <span>Realize o lançamento de gastos, cadastre boletos manualmente no sistema e edite boletos de notas fiscais.</span>
            <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close" @click="$store.dispatch('toggleTelaAjuda', $route.path.substring(1, $route.path.lastIndexOf('/')))"></button>
         </div>
      </div>

		<div id="search-nav" class="col-12 d-block btn-group dropup">
         <button type="button" class="btn btn-primary dropdown-toggle d-block d-lg-none" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">
            <i class="far fa-search font-17 mx-auto"></i><i class="fal fa-times font-17 mx-auto"></i>
         </button>
         <div class="card mb-2 dropdown-menu dropdown-menu-left">
            <div class="card-body pb-0">
               <div class="row">
                  <div class="col-md mb-3">
							<label><i class="far fa-hashtag color-theme font-11 me-1"></i> Nome/CNPJ/Chave</label>
                     <input type="text" class="form-control" v-model="pesquisa.nome" maxlength="200" v-focus @keyup.enter="buscarBoletos" placeholder="Busque por chave do boleto, chave da nota, razão social, nome ou CNPJ do emitente" />
                  </div>
						<div class="col-md-4 col-xl-2 px-0 mb-3 align-self-end">
							<div class="row">
                        <div class="col">
									<button type="button" class="btn btn-primary w-100" @click="buscarBoletos"><i class="fas fa-search font-13"></i></button>
                        </div>
                        <div class="col-8" v-if="dadosUsuario.usuarioPermissoes.includes('Financeiro | Editar gastos')">
									<button type="button" class="btn btn-primary w-100" @click="abrirModal(null)">
										<i class="far fa-plus font-13 me-1"></i> Adicionar
									</button>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>

		<div class="col-12" v-if="pesquisa.retorno.resultado.length > 0">
			<div class="card mb-0">
				<div class="card-body p-12">
					<div class="row">
						<div class="col mb-2 align-self-center">
							<div class="limitador-1 mb-1"><i class="fas fa-angle-right color-theme me-1"></i> {{ pesquisa.retorno.resultado.length == 100 ? 'Limitado a ': '' }} {{ pesquisa.retorno.resultado.length }} resultado(s)</div>
						</div>
						<div class="w-max-content mb-2">
							<a href="javascript:;" class="badge badge-default bg-theme mb-1" @click="exportar"><i class="far fa-share font-10 me-1"></i> Exportar</a>
						</div>

						<lancamento v-for="(lancamento, index) in pesquisa.retorno.resultado" :key="index" :lancamento="lancamento" :index="index" :showEdit="true" 
							@editar="abrirModal($event)" />
					</div>
				</div>
			</div>
      </div>

		<!-- modalGasto -->
      <div class="modal fade" id="modalGasto" tabindex="-1" aria-labelledby="modalGastoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md h-100 modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content h-100">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalGastoLabel">{{ modal.id == null ? 'Adicionar' : 'Editar' }} gasto {{ modal.id == null ? '' : ('('+ modal.nomeEmitente +')') }}</h5>
                  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-2">
							<div class="card-body p-12">
								<ul class="nav nav-pills" id="pills-tab" role="tablist">
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0 active" id="pills-principal-tab" data-bs-toggle="pill" data-bs-target="#pills-principal" type="button" role="tab" aria-controls="pills-principal" aria-selected="true">
											<i class="far fa-home font-12"></i><span class="ms-1 d-none d-md-inline"> Dados emitente</span>
										</button>
									</li>
									<li class="nav-item col" role="presentation">
										<button class="nav-link m-0" id="pills-boletos-tab" data-bs-toggle="pill" data-bs-target="#pills-boletos" type="button" role="tab" aria-controls="pills-boletos" aria-selected="false">
											<i class="far fa-barcode font-12"></i><span class="ms-1 d-none d-md-inline"> Boletos</span>
										</button>
									</li>
								</ul>
							</div>
						</div>

						<div class="tab-content" id="pills-tabContent">
							<div class="tab-pane fade show active" id="pills-principal" role="tabpanel" aria-labelledby="pills-principal-tab">
								<div class="card mb-5">
									<div class="card-body p-12">
										<div class="row">
											<div class="col-12 mb-3">
												<label><i class="far fa-file color-theme font-11 me-1"></i> Nota</label>
												<div class="input-group">
													<div class="input-group-text">
														<i class="fal cursor-pointer" :class="config.searchNota ? 'fa-undo' : 'fa-search'" @click="config.searchNota = !config.searchNota"
															:title="config.searchNota ? 'Voltar' : 'Buscar'"></i>
													</div>
													<input v-if="!config.searchNota" @click="config.searchNota = true;" :value="modal.chaveNota" title="Buscar nota" 
														type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção" />
													<autoCompleteNota v-else class="col" :modelo="'55'" @submit="setNotaTemp($event)" />
												</div>
											</div>
											<div class="col-12 mb-3">
												<label><i class="far fa-envelope color-theme font-10 me-1"></i> Emitente *</label>
												<div class="input-group">
													<div class="input-group-text">
														<i class="fal cursor-pointer" :class="config.searchEmitente ? 'fa-undo' : 'fa-search'" @click="config.searchEmitente = !config.searchEmitente"
															:title="config.searchEmitente ? 'Voltar' : 'Buscar'"></i>
													</div>
													<input v-if="!config.searchEmitente" @click="config.searchEmitente = true;" :value="modal.cpfCnpjEmitente" title="Buscar emitente" 
														type="text" class="form-control cursor-pointer bg-transparent" readonly placeholder="Selecione uma opção" />
													<autoCompleteDestinatario v-else class="col" :tipo="'Fornecedor'" @submit="setEmitenteTemp($event)" />
												</div>
											</div>
											<div class="col-12">
												<label><i class="far fa-store color-theme font-10 me-1"></i> Loja destinatário *</label>
												<v-select name="Desktop" :options="dadosUsuario.usuarioLojas" v-model="modal.idLojaEntrada" label="nomeFantasia" :reduce="loja => loja.id" :disabled="modal.chaveNota != null" placeholder="Selecione uma opção">
													<template #option="option">{{ option.nomeFantasia }}<small class="mx-2"><i class="fal fa-grip-lines-vertical"></i></small><i class="far fa-tag font-11 me-1"></i> {{ option.numeroLoja }}</template>
													<template v-slot:no-options>
														<span class="d-block lh-sm my-1">Sem resultados</span><small class="d-block lh-sm mb-1 opacity-90 font-11">Solicite permissão ou utilize a tela "Lojas" para adicionar</small>
													</template>
												</v-select>
												<select name="Mobile" class="form-control" v-model="modal.idLojaEntrada" :disabled="modal.chaveNota != null">
													<option v-for="(option, index) in dadosUsuario.usuarioLojas" :key="index" :value="option.id">{{ option.nomeFantasia }}&nbsp;&nbsp;|&nbsp;&nbsp;nº {{ option.numeroLoja }}</option>
												</select>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="tab-pane fade" id="pills-boletos" role="tabpanel" aria-labelledby="pills-boletos-tab">
								<div class="row">
									<div class="col-12 px-0">
										<div class="card mb-2">
											<div class="card-body">
												<h6 class="mb-0 font-14 weight-400">
													<span>{{ modal.boletos.length }} boleto(s) encontrado(s)</span>
													<a href="javascript:;" class="float-end font-16" @click="toggleBoleto(null)"><i class="fas fa-plus color-theme"></i></a>
												</h6>

												<div class="w-100 my-5 text-center" v-if="modal.boletos.length == 0">
													<i class="fal fa-align-slash font-60 text-secondary opacity-50 d-block mb-3"></i>
													<h4 class="font-12 mt-4 mb-0 text-secondary text-uppercase">Clique no ícone <i class="far fa-plus mx-1"></i> para adicionar</h4>
												</div>
											</div>
										</div>
									</div>

									<boleto v-for="(boleto, index) in modal.boletos" :key="index" :boleto="boleto" :index="index" :showEdit="true" 
										@editar="toggleBoleto($event)" @remover="excluirBoletoTemp($event)" />
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="salvarBoletos">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
               </div>
            </div>
         </div>
      </div>

		<!-- modalBoleto -->
      <div class="modal fade" id="modalBoleto" tabindex="-1" aria-labelledby="modalBoletoLabel" aria-hidden="true">
         <div class="modal-dialog modal-md modal-dialog-centered modal-dialog-scrollable">
            <div class="modal-content">
               <div class="modal-header">
                  <h5 class="modal-title" id="modalBoletoLabel">{{ boleto.id == null ? 'Adicionar' : 'Editar' }} boleto</h5>
                  <button type="button" class="btn-close" data-bs-toggle="modal" data-bs-target="#modalGasto" aria-label="Close"></button>
               </div>
               <div class="modal-body">
						<div class="card mb-5">
							<div class="card-body p-12 pb-0">
								<div class="row">
									<div class="col-6 mb-3" v-if="boleto.id == null">
										<label><i class="far fa-stream color-theme font-10 me-1"></i> Forma pagamento</label>
										<v-select name="Desktop" :options="['Parcela Única', 'Várias parcelas']" v-model="boleto.formaPgto" :searchable="false" :clearable="false" placeholder="Selecione uma opção" />
										<select name="Mobile" class="form-control" v-model="boleto.formaPgto">
											<option v-for="(option, index) in ['Parcela Única', 'Várias parcelas']" :key="index" :value="option">{{ option }}</option>
										</select>
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-calendar color-theme font-10 me-1"></i> Vencimento <span v-if="boleto.formaPgto == 'Várias parcelas'">(1ª parcela) </span>*</label>
										<datePicker format="DD/MM/YYYY" :clearable="false" :editable="false" v-model="boleto.dataDeVencimento" :disabled="modal.chaveNota != null && boleto.id != null"></datePicker>
									</div>
									<div class="col-6 mb-3" v-if="boleto.formaPgto == 'Várias parcelas'">
										<label><i class="far fa-copy color-theme font-10 me-1"></i> Quant. parcelas *</label>
										<input type="text" class="form-control" v-model="boleto.totalDeParcelas" v-mask="'##########'" />
									</div>
									<div class="col-6 mb-3" v-else>
										<label><i class="far fa-file color-theme font-10 me-1"></i> Número parcela *</label>
										<input type="text" class="form-control" v-model="boleto.numeroDaParcela" v-mask="'##########'" :disabled="modal.chaveNota != null && boleto.id != null" />
									</div>
									<div class="col-6 mb-3">
										<label><i class="far fa-dollar-sign color-theme font-10 me-1"></i> Valor *</label>
										<input type="text" class="form-control text-end" v-model="boleto.valor" :disabled="modal.chaveNota != null && boleto.id != null" />
									</div>
									<div class="mb-3" v-if="boleto.formaPgto == 'Parcela Única'" :class="boleto.id == null ? 'col-12' : 'col-6'">
										<label><i class="far fa-barcode color-theme font-10 me-1"></i> Código boleto</label>
										<input type="text" class="form-control" v-model="boleto.codigo" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="modal-footer">
						<button type="button" class="btn btn-primary" @click="saveBoletoTemp">Salvar</button>
                  <button type="button" class="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalGasto">Voltar</button>
               </div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

import $ from 'jquery'
import { mapState } from 'vuex'
import boleto from '@/components/financeiro/Boleto.vue'
import lancamento from '@/components/financeiro/Lancamento.vue'
import autoCompleteNota from '@/components/modelos/AutoCompleteNota.vue'
import autoCompleteDestinatario from '@/components/modelos/AutoCompleteDestinatario.vue'

export default {
	name: 'Lançamento_de_gastos',
	data: function () {
      return {
			pesquisa: {
				'loja': null, 'data': null, 'status': null, 'nome': '', 'agruparEmitente': false, 'apenasManual': true,
				'retorno': {'loja': null, 'data': null, 'status': null, 'nome': null, 'resultado': []}
			},
			modal: {"id": null, "boletos": [], "nomeEmitente": null, "cpfCnpjEmitente": null, "lojaNome": null, "lojaNumero": null, "idLojaEntrada": null, "chaveNota": null, "dataNota": null},
			boleto: {'formaPgto': 'Parcela Única', 'id': null, 'codigo': '', 'dataDeVencimento': new Date(), 'manual': true, 'pago': false, 'numeroDaParcela': 1, 'totalDeParcelas': 1, 'valor': '0.00'},
			config: {'searchNota': false, 'searchEmitente': false}
		}
	},
	computed: {
      ... mapState({
         dadosUsuario: state => state.dadosUsuario,
			telaAjuda: state => state.telaAjuda,
         urlRest: state => state.urlRest,
         tabs: state => state.tabs
      })
   },
	watch: {
		$data: {
			handler: function(value) {
				this.$store.dispatch('attTabData', JSON.parse(JSON.stringify({'id': this.$route.params.idTab, 'data': value})))
			},
			deep: true
		},
		'$route.params.idTab': function (value) {
         Object.assign(this.$data, this.tabs[value].data)
      },
		'boleto.dataDeVencimento' : function (value) {
			if (!(value instanceof Date)) {
				this.boleto.dataDeVencimento = new Date(value)
			}
      },
		'boleto.valor' : function (newVal) {
			this.$store.dispatch('formatValue', {'valor': newVal, 'digitos': 2}).then((valor) => {
				this.boleto.valor = valor
			})
		}
	},
	components: {
      boleto, lancamento, autoCompleteNota, autoCompleteDestinatario
   },
	methods: {
		exportar : function () {
			this.$store.dispatch('exportarExcel', {
				'nome': 'Lancamento_de_gastos',
				'resultado': this.pesquisa.retorno.resultado, 
				'campos': ['id', 'nomeEmitente', 'cpfCnpjEmitente', 'lojaNome', 'lojaNumero', 'chaveNota', 'dataNota', 'observacoesEmitente'], 
				'lista': {'nome': 'boletos', 'campos': ['codigo', 'dataDeVencimento', 'numeroDaParcela', 'totalDeParcelas', 'valor']}
			})
		},
		abrirModal : function (lancamento) {
			if (lancamento == null) {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
			} else {
				this.modal = {
					"id": lancamento.id, 
					"boletos": lancamento.boletos.map(b => {
						b.dataDeVencimento = (b.dataDeVencimento == null ? null : new Date(String(b.dataDeVencimento).replace(/-/g, "/")));
						return b;
					}), 
					"nomeEmitente": lancamento.nomeEmitente, 
					"cpfCnpjEmitente": lancamento.cpfCnpjEmitente, 
					"lojaNome": lancamento.lojaNome, 
					"lojaNumero": lancamento.lojaNumero, 
					"idLojaEntrada": lancamento.idLojaEntrada, 
					"chaveNota": lancamento.chaveNota, 
					"dataNota": lancamento.dataNota
				}
			}

			$('#modalGasto').modal('show')
		},
		buscarBoletos : function () {
			this.$store.dispatch('alternarTelaCarregamento', true)
			Object.assign(this.$data.pesquisa.retorno, this.$options.data.apply(this).pesquisa.retorno)

			this.$axios({
				method: 'post',
				url: this.urlRest +'financeiro/searchBoletos',
				headers: {'Content-Type': 'application/json'},
				data: this.pesquisa

			}).then(response => {
				this.pesquisa.retorno = response.data

			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		},
		setNotaTemp : function (result) {
			this.excluirTemp().then(() => {
				this.config.searchNota = false;
				this.config.searchEmitente = false;
				this.modal.chaveNota = result.chave;
				this.modal.cpfCnpjEmitente = result.cpfCnpjEmitente;
				this.modal.idLojaEntrada = result.idLoja;
				this.modal.boletos = result.boletos.map(b => {
					b.dataDeVencimento = (b.dataDeVencimento == null ? null : new Date(b.dataDeVencimento.year + '/' + b.dataDeVencimento.monthValue + '/' + b.dataDeVencimento.dayOfMonth));
					return b;
				})
			})
		},
		setEmitenteTemp : function (result) {
			this.excluirTemp().then(() => {
				this.modal.cpfCnpjEmitente = result.cpfCnpj
				this.config.searchEmitente = false;
				this.config.searchNota = false;
			})
		},
		toggleBoleto : function (boleto) {
         if (boleto) {
            let boletoObj = JSON.parse(JSON.stringify(boleto))
            this.boleto = {
					'formaPgto': 'Parcela Única',
					'id': boletoObj.id, 
					'codigo': boletoObj.codigo, 
					'dataDeVencimento': boletoObj.dataDeVencimento == null ? null : new Date(boletoObj.dataDeVencimento), 
					'manual': boletoObj.manual, 
					'pago': boletoObj.pago,
					'numeroDaParcela': boletoObj.numeroDaParcela == null ? 1 : boletoObj.numeroDaParcela, 
					'totalDeParcelas': boletoObj.totalDeParcelas == null ? this.modal.boletos.length : boletoObj.totalDeParcelas, 
					'valor': boletoObj.valor == null ? '0.00' : parseFloat(boletoObj.valor).toFixed(2)
				}
         } else {
            this.boleto = {
					'formaPgto': 'Parcela Única', 'id': null, 'codigo': '', 'dataDeVencimento': new Date(), 'manual': true, 'pago': false,
					'numeroDaParcela': (this.modal.boletos.length + 1), 'totalDeParcelas': (this.modal.boletos.length + 1), 'valor': '0.00'
				}
         }

			$('#modalGasto').modal('hide')
			$('#modalBoleto').modal('show')
      },
		saveBoletoTemp : function () {
			if ((this.boleto.formaPgto == 'Parcela única' && (this.boleto.codigo == null || String(this.boleto.codigo).trim().length == 0)) || 
				(this.boleto.formaPgto == 'Várias parcelas' && (this.boleto.totalDeParcelas == null || String(this.boleto.totalDeParcelas).trim().length == 0)) ||
				this.boleto.dataDeVencimento == null) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			if ((this.boleto.formaPgto == 'Várias parcelas' && (!/^[0-9]+$/.test(this.boleto.totalDeParcelas) || parseFloat(this.boleto.totalDeParcelas) < 1)) || 
				(!/^[0-9]+$/.test(this.boleto.numeroDaParcela) || parseFloat(this.boleto.numeroDaParcela) < 1) ||
				(this.modal.boletos.filter(b => b.numeroDaParcela == this.boleto.numeroDaParcela && b.id != this.boleto.id).length > 0)) {
				this.$toast.fire({
					icon: 'error',
					title: 'Parcelas inválidas'
				});

				return
			}

			if (!/^[0-9.]+$/.test(this.boleto.valor) || parseFloat(this.boleto.valor) < 0.01) {
				this.$toast.fire({
					icon: 'error',
					title: 'Valor inválido'
				});

				return
			}

			if (this.boleto.id) {
				let index = this.modal.boletos.findIndex(b => b.id == this.boleto.id)
            this.$set(this.modal.boletos, index, JSON.parse(JSON.stringify(this.boleto)))
				
			} else if (this.boleto.formaPgto == 'Várias parcelas') {
				for (let index = 1; index <= parseInt(this.boleto.totalDeParcelas); index++) {
					let vencimento = new Date(this.boleto.dataDeVencimento);
					vencimento.setMonth(vencimento.getMonth() + (index - 1))
	
					let boleto = {
						'formaPgto': this.boleto.formaPgto,
						'id': this.boleto.id,
						'codigo': this.boleto.codigo,
						'dataDeVencimento': vencimento,
						'manual': this.boleto.manual,
						'pago': this.boleto.pago,
						'numeroDaParcela': this.modal.boletos.length + 1,
						'totalDeParcelas': this.boleto.totalDeParcelas,
						'valor': this.boleto.valor
					}
					
					this.modal.boletos.push(boleto)
				}
			} else {
				this.modal.boletos.push(JSON.parse(JSON.stringify(this.boleto)))
			}

			$('#modalBoleto').modal('hide')
			$('#modalGasto').modal('show')
			setTimeout(() => Object.assign(this.$data.boleto, this.$options.data.apply(this).boleto), 50);
		},
		excluirBoletoTemp : function (index) {
			this.modal.boletos.splice(index, 1)
		},
		excluirTemp : function () {
			/* eslint-disable-next-line no-unused-vars */
			return new Promise((resolve, reject) => {
				Object.assign(this.$data.modal, this.$options.data.apply(this).modal)
				resolve()
			})
		},
		salvarBoletos : function () {
			if (this.modal.cpfCnpjEmitente == null || this.modal.idLojaEntrada == null || this.modal.boletos.length == 0) {
				this.$toast.fire({
					icon: 'error',
					title: 'Preencha todos os campos obrigatórios'
				});

				return
			}

			this.$store.dispatch('alternarTelaCarregamento', true)

			this.$axios({
				method: 'post',
				url: this.urlRest +'financeiro/saveLancamentoGasto',
				headers: {'Content-Type': 'application/json'},
				data: this.modal

			}).then(() => {
				$('#modalGasto').modal('hide')
				this.buscarBoletos()

				this.$toast.fire({
					icon: 'success',
					title: 'Boletos salvos!'
				})
			}).catch((error) => {
				if (error.response != undefined) {
					if (error.response.status == 408) {
						this.$store.dispatch('deslogar')

						this.$toast.fire({
							icon: 'warning',
							title: 'Sessão expirada!'
						});
					} else {
						this.$toast.fire({
							icon: 'error',
							title: 'Erro: ' + error.response.status
						});
					}
				}
			}).finally(() => {
				this.$store.dispatch('alternarTelaCarregamento', false)
			});
		}
	},
	mounted() {
		Object.assign(this.$data, this.tabs[this.$route.params.idTab].data)
	}
}

</script>